.page {
    display: none;
    width: 100%;
    min-height: calc(var(--vh, 1vh)*100);
    z-index: 5;
}

.home {
    position: absolute;
    right: calc(var(--pad)*1);
    bottom: calc(var(--pad)*6);
    white-space: nowrap;
    line-height: 1.5;
}

.home_aboutme span {
    display: block;
}

.info {
    position: absolute;
    right: calc(var(--pad)*1);
    bottom: calc(var(--pad)*14);
    white-space: nowrap;
    line-height: 1.5;
    text-align: right;
    overflow: none;
    z-index: 7;
}

.project {
    padding: calc(var(--pad)*2 + 140px) calc(var(--pad)*1);
    text-align: right;
    white-space: nowrap;
}

@media(min-width: 600px) {
    .project {
        max-height: fit-content;
        padding: calc(var(--pad)*2 + 96px) calc(var(--pad)*1);
    }
    .info {
        right: calc(var(--pad)*2);
        bottom: calc(var(--pad)*2);
    }
    .home {
        right: calc(var(--pad)*2);
        bottom: calc(var(--pad));
        z-index: 9999;
    }
    .home_aboutme {
        max-height: 80vh;
        overflow: scroll;
    }
}

.project_heading {
    font-weight: 500;
    padding-bottom: 5px;
}
.project_subheading {
    font-weight: 400;
    padding-bottom: 40px;
    font-style: italic;
}

.project_list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 15px;
    max-height: 300px;
    overflow: scroll;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin: 0;
}

*, *:before, *:after {
    box-sizing: inherit;
    -webkit-user-select: inherit;
    user-select: inherit;
    -webkit-user-drag: inherit;
    -webkit-tap-highlight-color: inherit;
    backface-visibility: inherit;
}

img.animated-gif{
    width: 99vh;
    max-width: 100%;
    text-align: center;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }


.h1 {
    font-weight: 100;
    font-size: 16vw;
    font-family: Cormorant;
    color: var(--body_color);
}

.h2 {
    font-weight: 900;
    font-size: 12px;
    color: var(--body_color);
}

body .modal-dialog { 
    max-width: 100%;
    width: auto !important;
    display: inline-block;
}

.modal {
    padding: 100px;
}
  
.modal-open .modal {
     z-index: 1050;
}

.text {
    color: var(--body_color);
    font-weight: 400;
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    line-height: 1.9em;
    display: block;
    position: relative;
}

.quote {
    color: var(--body_color);
    font-weight: 300;
    font-style: italic;
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    line-height: 1.9em;
    display: block;
    position: relative;
}

.smallHeading {
    letter-spacing: 2px;
    text-transform: uppercase;
    border: 1px var(--body_color);
    border-bottom-style: solid;
    margin-bottom: 16px;
    padding-top: 15px;
    padding-bottom: 0;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    color: var(--body_color);
}

.smallHeading-thin {
    letter-spacing: 2px;
    text-transform: uppercase;
    border: 1px var(--body_color);
    border-bottom-style: solid;
    padding-top: 10px;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    color: var(--body_color);
    cursor: pointer;
}


.item {
    height: 30rem;
    width: "auto";
    padding-left: 5px;
}

.item img {
    object-fit: contain;
    width: 100%;
    height:100%;
}

.inner-carousel {
    display:flex;
}

.carousel {
    cursor: grab;
    overflow: hidden;
}

.skill {
    padding-bottom: 10px;
}

.email {
    text-decoration: underline;
    padding-left: 5px;
}

._space {
    padding-right: 5px;
}

.info_section {
    display: block;
    font-family: Montserrat, sans-serif;
    font-size: 13px;
    font-weight: 400;
}

.email:hover {
    text-decoration: none;
}

.profile {
    position: absolute;
    bottom:0;
    max-height:  55%;
    width: auto;
    opacity: 0.9;
    z-index: 4;
}   
