.mainHeader {
    position: fixed;
    z-index: 10;
    left: calc(var(--pad)*2);
    top: calc(var(--pad)*2);
}

.mainHeader_title {
    margin: -0.1em 0 0 -0.04em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: -1px;
}

.mainHeader_label {
    margin-top: 5px;
    font-family: 'Cormorant';
    font-weight: 600;
}

.mainHeader_nav {
    margin-top: 40px;
    font-size: 16px;
}


.mainHeader_nav ol {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: var(--body_color);
    background-color: transparent;
} 


  
