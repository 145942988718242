:root{
  --body_background: hsl(0, 0%, 88%);
  --body_color: hsl(0, 0%, 8%);
  --container: hsl(0, 0%, 90%);
  --pad: max(20px, 1vmin);
  --filter: none;
}

[data-theme='dark']{
  --body_background: hsl(0, 0%, 0%);
  --container: hsl(0, 0%, 8%);
  --body_color: hsl(0, 0%, 88%);
  --filter: invert(0.8);
} 

body {
  background-color: var(--c-bg);
  font-family: Cormorant;
  font-weight: 600;
  font-size: 14px;
  font-feature-settings: "palt";
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

html,body {
  height: 100%;
}

html {
  line-height: 1;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: var(--body_color);
}

#root {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--body_background);
  color: var(--body_color);
  white-space: nowrap;
  transition-property: background-color;
  transition-duration: .9s;
  overflow-X: auto;
  transition-timing-function: cubic-bezier(0.1, 0.4, 0.2, 1);
  scroll-behavior: smooth;
}

#Container {
  position: fixed;
  z-index: -1;
  left: var(--pad);
  right: var(--pad);
  top: var(--pad);
  bottom: var(--pad);
  margin: 0px;
  overflow: hidden;
  background-color: var(--container);
}

#Container .air{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(https://1.bp.blogspot.com/-xQUc-TovqDk/XdxogmMqIRI/AAAAAAAACvI/AizpnE509UMGBcTiLJ58BC6iViPYGYQfQCLcBGAsYHQ/s1600/wave.png);
  background-size: 1000px 100px;
  
}
#Container .air.air1{
  animation: wave 30s linear infinite;
  z-index: 4;
  opacity: 1;
  animation-delay: 0s;
  bottom: 0;
  filter: var(--filter);
}
#Container .air.air2{
  animation: wave2 20s linear infinite;
  z-index: 3;
  opacity: 0.4;
  animation-delay: -5s;
  bottom: 10px;
  filter: var(--filter);
}
#Container .air.air3{
  animation: wave 15s linear infinite;
  z-index: 2;
  opacity: 0.5;
  animation-delay: -2s;
  bottom: 15px;
  filter: var(--filter);
}
#Container .air.air4{
  animation: wave2 15s linear infinite;
  z-index: 1;
  opacity: 0.6;
  animation-delay: -5s;
  bottom: 20px;
  filter: var(--filter);
}
@keyframes wave{
  0%{
    background-position-x: 0px; 
  }
  100%{
    background-position-x: 1000px; 
  }
}
@keyframes wave2{
  0%{
    background-position-x: 0px; 
  }
  100%{
    background-position-x: -1000px; 
  }
}

.borders {
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.border_top, .border_bot {
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--pad);
  background-color: var(--body_background);
  transition-property: background-color;
  transition-duration: .9s;
  transition-timing-function: cubic-bezier(0.1, 0.4, 0.2, 1);
  opacity: .9;
}
.border_top {
    top: 0;
}

.border_bot {
    bottom: 0;
}

.frame {
  position: fixed;
  z-index: 9;
  left: var(--pad);
  right: var(--pad);
  top: var(--pad);
  bottom: var(--pad);
  pointer-events: none;
}

.frame_border {
  position: absolute;
  background-color: var(--body_color);
  opacity: 0.9;
}

.frame_left {
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
}

.frame_right {
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
}

.frame_top {
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
}

.frame_bot {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
}


* {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 30px;
}
 
p {
  font-size: 14px;
  font-weight: 900;
}

@media only screen and (max-width: 600px) {
  p {
    font-size: 14px;
    font-weight: 900;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  p {
    font-size: 16px;
    font-weight: 700;
  }
}
@media only screen and (min-width: 1025px) {
  p {
    font-size: 18px;
    font-weight: 600;

  }
}

h2,h3,h4,h5,h6 {
    font-weight: inherit;
    font-size: inherit;
    display: block;
}

::-webkit-scrollbar {
  display: none;
}

.Enter {
  column-gap: .5em;
  font-size: 30px;
  font-family: Cormorant;
}

._t1 {
  font-weight: 400;
  font-size: 30px;
  font-family: Cormorant;
}

._t2 {
  font-weight: 200;
}